html{
    min-height:100%;/* make sure it is at least as tall as the viewport */
    position:relative;
}
body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

warning {
	color: orange;
}

label span {
	display: inline-block;
	width: 160px;
	text-align: right;
}

.hatter {
  min-height: 100vh;
}

.checkboxes div {
  margin-bottom: 5px;
}
.checkboxes input {
  float:left;
  margin-right:20px;
}

.checkboxes label {
  overflow:auto;
  display:block;
}

.checkboxquiz-input {
  margin: 5px 20px 10px 10px;
}
a {
  color: #ccc;
}
a:hover {
	color: #eee;
}
code {
  /*color: #7483FB;*/
  color: #333;
  font-weight: bold;
}
blockquote {
  font-style: italic;
}
select {
  font-size: 20pt;
  margin: 5px 0;
}
option {
  font-size: 20pt;
}
.textarea-wrapper {
  padding: 0px 15px 0px 0px;
  width: 100%;
}
textarea {
  font-size: 20pt;
  width: 100%;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
/*optgroup {
  font-size: 20pt;
}*/
div.questionbox {
  padding: 15px;
  margin-left: auto;
  margin-right: auto;
  width: 50%;
  background-color: #1380AE;
  font-size: 20pt;
  color: #fff;
}
.valasz {
/*  margin-left: 10px;
  margin-right: 10px;*/
  line-height: 20pt;
  font-size: 20pt;
}
.valasz-wrapper {
  padding-left: 10px;
  padding-right: 10px;
}
.kovgomb {
  background-color: #EDAE44;
  border: 1px solid #ca9336;
  height: 42px;
  width: 110px;
  color: #000;
  font-size: 15pt;
  cursor: pointer;
}
.showgomb {
  background-color: rgb(230,230,230);
  border: 1px solid rgb(200,200,200);
  height: 42px;
  width: 110px;
  color: #000;
  font-size: 15pt;
  cursor: pointer;
}
.jelszogomb {
  background-color: #EDAE44;
  border: 1px solid #ca9336;
  height: 42px;
  width: 200px;
  color: #000;
  font-size: 15pt;
  cursor: pointer;
}
.gomb {
  display: inline-block;
  text-decoration: none;
  background-color: #EDAE44;
  border: 1px solid #ca9336;
  color: #000;
  font-size: 15pt;
  padding: 10px 10px;
  margin: 5px 0;
  cursor: pointer;
}
.gomb:hover {
  color: #000;
}
p.textBox {
  white-space: pre-line;
}
p.remark {
  font-size: 75%;
}
div.droptarget {
    width: 200px;
    height: 35px;
    margin: 5px;
    border: 1px solid #aaaaaa;
}
#other {
  width: 200px;
  border: 1px solid #aaaaaa;
}
div.draggable {
  width: 180px;
  height: 15px;
	padding: 10px;
  font-size: 20px;
  color: white;
  font-weight: bold;
  line-height: 15px;
  text-align: center;
  vertical-align: middle;
  border: 1px solid #aaaaaa;
  cursor: pointer;
}
div.wrapper {
  overflow: hidden;
  /*border: 1px solid blue;*/
}
div.left {
  float: left;
  margin: 0 20px;
  /*border: 1px solid red;*/
}
div.right {
  overflow:hidden;
  margin: 0 20px;
  display: inline-block;
  /*border: 1px solid black;*/
}
div.valtozok {
  font-size: 16pt;
  border: 1px solid rgb(230, 230, 230);
  padding: 5px;
}
div.valtozok h1 {
  font-size: 20pt;
}
div.valtozok pre {
  font-size: 14pt;
}
pre,code,hr {
  color: rgb(230, 230, 230);
  font-weight: normal;
}
.centertext {
  text-align:center;
}
.evaluation-wrapper {
  padding: 20px;
}
.evaluation {
  white-space: pre-line;
  border: 1px solid black;
  width: 100%;
  table-layout: auto;
}
.evaluation td {
  border: 1px solid black;
  padding: 5px;
}
.evaluation td.evaluation-id {
  text-align: center;
}
.evaluation td.evaluation-points {
  text-align: center;
}
.evaluation .evaluation-one-row {
  width: 100%;
  float: left;
  border: 1px solid black;
}
.evaluation-header {
  font-weight: bold;
}
div.evaluation {
  float: left;
  border: none;
  width: 100%;
}
div.evaluation-one-block {
  float: left;
  width: 100%;
  margin-bottom: 10px;
  margin-top: 10px;
  background-clip: padding-box;
}
.evaluation .evaluation-one-row div {
  float:left;
  width: calc(50% - 4px);
  height:100%;
  padding: 2px;
}
.evaluation th {
  border: 1px solid black;
}

.evaluation-id {

}
.evaluation-answer {

}
.evaluation-solution {

}
.evaluation-points {

}
.evaluation-review {

}
.excellenteval {
  /*background-color: #21ff28;
  background-color: #d4edda;*/
  background-color: #1380ae;
  color: #fff;
  font-size: 200%;
}
.mediumeval {
  /*background-color: #21ff28;
  background-color: #fff3cd;
  background-color: #d4edda;*/
  background-color: #1380ae;
  color: #fff;
  font-size: 150%;
}
.badeval {
  /*background-color: #ff3b21;
  background-color: #d4edda;*/
  background-color: #1380ae;
  color: #fff;
}
.erroreval {
  background-color: #ff0000;
}
.infoeval {
  background-color: #1380AE;
  font-size: 150%;
  color: #ccc;
}
.infoeval .em {
  text-decoration: underline;
  color: #fff;
}
.eval {
  padding: 10px;
  margin: 50px calc(20% - 10px) 0;
  width: 60%;
  text-align: center;
  float: left;
}
iframe.mmform {
  width:704px;
  height:500px;
  border:0;
}
.fejlec {
  margin-left: 40px;
  margin-right: 40px;
  height: 190px;
  line-height: 20px;
}
.fejlec h2 {
  color: #bbb;
}
.fejlectarto {
  border-bottom: 1px solid #e7e7e7;
}
.hatter {
  background-color: #f8f8f8;
  background-size: 100%;
}
.logo {
  display: table-cell;
  vertical-align: middle;
  max-height: 90px;
  height: 90px;
}
.logo img {
  display: inline-block;
}
.resulttable {
  margin: 20px 0px;
}
.resulttable th {
  text-align: right;
  font-weight: 400;
  padding: 0 20px 0 0;
}
.resulttable td {
  font-weight: bolder;
}

@media only screen and (max-width: 1350px) {
    div.questionbox {
        width: 70%;
    }
}
@media only screen and (max-width: 1000px) {
    div.questionbox {
        width: 80%;
    }
}
@media only screen and (max-width: 600px) {
    div.questionbox {
        width: 90%;
    }
    div.questionbox img {
      width: 100%;
    }
    .valasz {
      width: 100%;
    }
}

@media only screen and (max-width: 380px) {
  .logo img {
    width: 100%;
    height: auto;
  }
}
